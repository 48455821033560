import * as React from "react"
import { Link, HeadFC } from "gatsby"

const pageStyles = {
  color: "#232129",
  padding: "96px",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  maxWidth: 320,
}

const paragraphStyles = {
  marginBottom: 48,
}
const codeStyles = {
  color: "#8A6534",
  padding: 4,
  backgroundColor: "#FFF4DB",
  fontSize: "1.25rem",
  borderRadius: 4,
}

const AboutUsPage = () => {
  return (
    <main style={pageStyles}>
      <h1 style={headingStyles}>Who we are</h1>
      <p style={paragraphStyles}>
      Are you looking for a unique and unforgettable travel experience? Look no further than our online travel agent platform specializing in attractions! Our platform offers a wide range of exciting and unique attractions and activities, all in one convenient location.
      <br/>
      <br/>
      With our platform, you can easily search for the best attractions and activities in your desired destination, whether you're looking for thrilling adventures or cultural experiences. We offer a user-friendly interface that makes it easy to compare prices, view reviews, and make bookings with just a few clicks.
      <br/>
      <br/>
      Our platform also offers a range of features to make your travel experience even more enjoyable, such as personalized recommendations, insider tips, and 24/7 customer support. Whether you're planning a solo trip, a family vacation, or a group excursion, we've got you covered.
      <br/>
      <br/>
      So why wait? Sign up for our online travel agent platform specializing in attractions today and start planning your next adventure!
        <br />
        {process.env.NODE_ENV === "development" ? (
          <>
            <br />
            Try creating a page in <code style={codeStyles}>src/pages/</code>.
            <br />
          </>
        ) : null}
        <br />
        {/* <Link to="/">Go home</Link>. */}
      </p>
    </main>
  )
}

export default AboutUsPage;

export const Head: HeadFC = () => <title>Not found</title>
